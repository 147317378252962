<template>
  <div
    style="display: flex; height: 100%; width: 100%; background-color: white"
  >
    <v-row style="flex-wrap: nowrap">
      <v-col
        style="
          padding: 0px;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        "
      >
        <v-col
          style="
            max-width: 370px;
            max-height: 340px;
            height: 100%;
            margin-right: 30px;
            margin-left: 30px;
            display: flex;
            flex-direction: column;
          "
        >
          <div
            style="
              display: flex;
              height: 64px;
              flex-direction: column;
              justify-content: space-between;
            "
          >
            <h3>{{ $t("ChangePassword") }}</h3>
            <div class="content" style="margin-top: 12px">{{ userEmail }}</div>
          </div>
          <div
            style="
              display: flex;
              flex-direction: column;
              gap: 12px;
              margin-top: 32px;
            "
          >
            <v-text-field
              name="password-input"
              type="password"
              prepend-inner-icon="mdi-lock"
              outlined
              v-model="password"
              placeholder="Parool"
              hide-details
            ></v-text-field>
            <v-text-field
              @keydown.enter="submit"
              name="password-input"
              type="password"
              prepend-inner-icon="mdi-lock"
              outlined
              v-model="passwordConfirmation"
              placeholder="Kinnita parool"
              hide-details
            ></v-text-field>
          </div>
          <div style="margin-bottom: auto; margin-top: 15px; color: red">
            {{ this.passwordConfirmationMessage }}
          </div>
          <div style="display: inline-flex">
            <div class="add-button" @click="submit">
              <img
                style="margin-right: 9px"
                alt=""
                src="../assets/images/checkmarkCircled.svg"
              />
              <p>
                {{ $t("ChangePassword") }}
              </p>
            </div>
          </div>
        </v-col>
      </v-col>
      <v-col
        style="
          max-height: 100%;
          max-width: 50%;
          padding: 0px;
          display: flex;
          align-items: center;
          justify-content: center;
        "
      >
        <img
          style="
            margin: 64px 64px 64px 32px;
            background-color: #f4f5f7;
            border-radius: 12px;
            max-width: 624px;
            max-height: 773px;
            height: 100%;
            width: 100%;
          "
          alt=""
          src="../assets/images/loginImage.svg"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ResetPassword",
  data() {
    return {
      userEmail: "",
      password: "",
      passwordConfirmation: "",
      passwordConfirmationMessage: "",
    };
  },
  created() {
    this.getEmail();
  },
  watch: {
    passwordConfirmation() {
      if (
        this.password.length === this.passwordConfirmation.length &&
        this.password !== this.passwordConfirmation
      ) {
        this.passwordConfirmationMessage = this.$t(
          "FormErrors.PasswordsDontMatch"
        );
      } else {
        this.passwordConfirmationMessage = "";
      }
    },
  },
  methods: {
    async getEmail() {
      await axios
        .get(`/api/new-user-email/${this.$route.params.id}`)
        .then((res) => {
          this.userEmail = res.data[0].email;
        })
        .catch((err) => {
          console.log(err);
          this.$router.push({ name: "Login" });
        });
    },
    async submit() {
      if (this.password.length < 8) {
        this.passwordConfirmationMessage = this.$t(
          "FormErrors.Password8Characters"
        );
        return;
      }

      if (this.password === this.passwordConfirmation) {
        const request = {
          activationId: this.$route.params.id,
          password: this.password,
        };
        await axios
          .post("/api/reset-password", request)
          .then((res) => {
            console.log(res);
            this.$router.push({
              name: "Login",
              params: { email: this.userEmail, password: this.password },
            });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
};
</script>

<style scoped>
.add-button {
  cursor: pointer !important;
  padding-left: 16px;
  padding-right: 16px;
}
.add-button p {
  color: white;
}
</style>
